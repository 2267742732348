<template>
  <div>
    <!-- 검색 -->
    <div class="searchArea w50 mt-15">
      <v-select
        v-model="search.by"
        :items="searchByOptions"
        item-text="text"
        item-value="abbr"
        :label="Label_By"
        outlined
        dense
        hide-details
        class="form-select"
      ></v-select>

      <v-text-field
        v-model="search.value"
        @keyup.enter="searchResults"
        :placeholder="$t(searchValuePlaceholder)"
        outlined
        dense
        hide-details
        class="form-input in-btn ml-2"
        clearable
         @keydown="clearTabindex"
      >
        <template v-slot:append>
          <v-btn @click="searchResults" text>
            <img src="@/assets/img/btn-search.jpg" alt="" />
          </v-btn>
        </template>
      </v-text-field>
    </div>

    <!-- data table -->
    <v-data-table
      v-model="selected"
      :headers="resultHeaders"
      :items="results"
      item-key="no"
      :options.sync="options"
      :server-items-length="totalResults"
      :single-select="true"
      :hide-default-footer="true"
      class="tbl-type01 mt-10"
      clearable
      :sort-by.sync="sortBy"
       @keydown="clearTabindex"
    >
             <template v-slot:[`item.positionId`]="{ item }">
               {{ item.positionId ? item.positionId : '-' }}
             </template>
             <template v-slot:[`item.floor`]="{ item }">
               {{ item.floor ? item.floor : '-' }}
             </template>

             <template v-slot:[`item.section`]="{ item }">
               {{ item.section ? item.section : '-' }}
             </template>
             <template v-slot:[`item.coordinateX`]="{item}">
      X : {{item.coordinateX | trunc}} <span style="margin-left:10px">Y : {{item.coordinateY | trunc}}</span>
    </template>
      <template slot="no-data">
        <p>
          {{ $t('No data available') }}
        </p>
      </template>
    </v-data-table>

    <!-- 하단 옵션 -->
    <div class="table-options">
        <!-- 특정 페이지 이동 -->
        <div class="pageInfo">{{ pageInfoText }}</div>
          <div class="pagination" style="width:100%;justify-content: end;">
            <v-pagination
              v-model="page"
              @input="paging"
              :length="totalPages * 1"
              :total-visible="7"
              color="#2f3b4c"
            ></v-pagination>
        <!-- end pagination 수정 -->
      </div>
    </div>
     <a ref="link" :style="{ display: 'none' }" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EventBus from '@/plugins/eventBus.js'
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'
import { setTempObj, getTempObj } from '@/plugins/sessionStorageManager'
import configs from '@/plugins/configs'
import axios from 'axios'
import Cookies from 'js-cookie'

const requests = {
  getResults: {
    method: 'get',
    url: '/lbs/common/lbs/result'
  }
}

const searchType = {
  getByStore: 'getByStore',
  getByLabelId: 'getByLabel',
  getByPosition: 'getByPosition',
  getByArticleId: 'getByArticleId',
  getByArticleName: 'getByArticleName'
}

export default {
  data () {
    return {
      user: null,
      page: 1,
      totalPages: 1,
      pageInfoText: '',
      resultSearchType: '',
      totalVisiblePages: 5,
      options: {},
      totalResults: null,
      results: [],
      selected: [],
      search: {
        by: 'getByPosition',
        value: null
      },
      sortOrderValue: '',
      sortKeyvalue: '',
      sortBy: 'positionId'
    }
  },
  filters: {
    trunc (value) {
      if (value.includes('.0')) {
        return Math.trunc(value)
      } else {
        return value
      }
    }
  },
  computed: {
    cloudMode () {
      return process.env.VUE_APP_PRODUCTION_TYPE === codes.productionTypes.CLOUD
    },
    searchByOptions () {
      return [
        {
          text: this.$t('Shelf Name'),
          abbr: 'getByPosition',
          originalText: 'Shelf Name'
        },
        {
          text: this.$t('Label ID'),
          abbr: 'getByLabel',
          originalText: 'Label ID'
        },
        {
          text: this.$t('Product ID'),
          abbr: 'getByArticleId',
          originalText: 'Product ID'
        },
        {
          text: this.$t('Product Name'),
          abbr: 'getByArticleName',
          originalText: 'Product Name'
        }
      ]
    },
    Label_By () {
      return this.$t('Location Search')
    },
    resultHeaders () {
      return [
        { text: '#', align: 'center', value: 'no', sortable: false },
        { text: this.$t('SHELF NAME'), value: 'positionId' },
        { text: this.$t('FLOOR'), value: 'floor' },
        { text: this.$t('SECTION NAME'), value: 'section' },
        { text: this.$t('SHELF COORDINATE'), value: 'coordinateX' },
        { text: this.$t('PRODUCT NAME'), value: 'articleName' },
        { text: this.$t('PRODUCT ID'), value: 'articleId' },
        {
          text: this.$t('LABEL ID'),
          value: this.cloudMode ? 'labelCode' : 'mac'
        }
      ]
    },
    ...mapGetters({
      store: 'dataStore/GET_SELECTED_STORE'
    }),
    searchValuePlaceholder () {
      let searchBy = this.searchByOptions.find(
        option => option.abbr === this.search.by
      ).originalText
      searchBy = searchBy.toLowerCase().replace('id', 'ID')
      return `Input the ${searchBy}`
    }
  },
  watch: {
    options: {
      handler () {
        if (this.isValidStr(this.search.value)) {
          this.searchResults()
        } else {
          // 선택된 스토가 있으면 실행
          if (this.store.code) {
            this.getResults(1)
            this.page = 1
          }
        }
      },
      deep: true
    },
    store: {
      handler () {
        this.getResults(1)
        this.page = 1
      }
    },
    search: {
      handler (newSearch) {
        const results = { search: newSearch }
        setTempObj('results', results)
      },
      deep: true
    }
  },
  created () {
    this.user = this.$store.state.auth.user
  },
  mounted () {
    // 선택된 스토가 없으면 팝업  Open
    if (!this.store.code) {
      EventBus.$emit('openSelectedStores')
    }
    const resultsSessionData = getTempObj('results')
    if (
      !commons.isNull(resultsSessionData) &&
      !commons.isNull(resultsSessionData.search)
    ) {
      this.search = resultsSessionData.search
    }
  },
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    isValidStr (val) {
      return val !== undefined && val !== null && val.length > 0
    },
    paging () {
      // 기존 조건값으로 페이지 요청 하도록 수정
      this.getResults(this.page, this.resultSearchType, this.store.code)
    },
    getPageInfoText (headers) {
      const page = this.page - 1
      if (
        headers['x-totalelements'] === 0 ||
        headers['x-totalelements'] === undefined
      ) {
        // return '0 to 0, 0 in total'
        return '0 to 0, 0' + ' ' + this.$t('in total')
      }
      return `${page * 1 * headers['x-size'] + 1} 
      ${this.$t('to')} ${page * 1 * headers['x-size'] + this.results.length},
      ${headers['x-totalelements'] * 1} ` + ' ' + this.$t('in total')
    },
    resetResults () {
      this.getResults(1)
      this.page = 1
      this.selected = []
    },
    searchResults () {
      const searchBy = this.search.by
      const searchValue = this.search.value
      if (!commons.isValidStr(searchValue)) {
        this.getResults(1)
        return
      }
      const getByCondition = `/${searchBy}/${searchValue}`
      this.getResults(1, getByCondition, this.store.code)
      this.page = 1
    },
    getResultSortKey (sortKey) {
      switch (sortKey) {
        case 'Location ID':
          sortKey = 'positionID'
          break
        case 'Label ID':
          sortKey = this.cloudMode ? 'labelCode' : 'labelId'
          break
        case 'Request Date':
          sortKey = 'requestDate'
          break
        default:
          break
      }
      return sortKey
    },
    insertIndex (list, headers) {
      if (!commons.isValidStr(list)) return []
      let idx = (this.page - 1) * Number(headers['x-size']) + 1
      if (list.length < 2) idx = 1
      list = list.map(el => {
        el.no = idx
        idx++
        return el
      })
      return list
    },
    handleGetResultResponse (res) {
      if (res.data.length < 1) return
      const results = this.insertIndex(res.data, res.headers)
      this.results = results.map(result => {
        result.articleName = result.articleName.pop()
        result.articleId = result.articleId.pop()
        return result
      })
      this.totalResults = this.anchors.length
      this.pageInfoText = this.getPageInfoText(res.headers)
      this.totalPages = 1
      this.selected = []
    },
    handleGetResultsResponse (res) {
      this.results = this.insertIndex(res.data.resultResponse, res.headers)
      this.totalResults = this.results.length
      this.pageInfoText = this.getPageInfoText(res.headers)
      this.totalPages = Number(res.headers['x-totalpages'])
      this.selected = []
    },
    handleGetResultsError (error) {
      this.results = []
      this.totalResults = this.results.length
      const headers = {}
      headers['x-totalelements'] = 0
      this.pageInfoText = this.getPageInfoText(headers)
      this.totalPages = 0
      this.selected = []
      console.log(error)
    },
    buildParmas () {
      const { sortBy, sortDesc, itemsPerPage } = this.options
      const params = { company: this.user.company }
      let sort = null
      if (sortBy.length === 1 && sortDesc.length === 1) {
        const sortKey = this.getResultSortKey(sortBy[0])
        this.sortKeyvalue = sortKey
        const sortOrder = sortDesc[0]
        this.sortOrderValue = sortOrder ? 'desc' : 'asc'
        sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
      }
      if (sort !== null) params.sort = sort
      if (itemsPerPage > 0) params.size = itemsPerPage
      return params
    },
    async getResults (page, resultSearchType = `/${searchType.getByStore}/${this.store.code}`, storeId = null) {
      // Cloud인 경우 sorting parameters 분기처리 필요. buildParmas에서 작업할것.
      var params = this.buildParmas()
      if (storeId !== null) params.storeId = storeId
      if (page !== null) params.page = page - 1
      var Newparams = new URLSearchParams()
      if (this.sortKeyvalue === 'coordinateX') {
        var coordinateY = 'coordinateY' + ',' + this.sortOrderValue
        Newparams.append('company', params.company)
        Newparams.append('page', params.page)
        Newparams.append('size', params.size)
        if (params.sort) {
          Newparams.append('sort', params.sort)
          Newparams.append('sort', coordinateY)
        }
      } else {
        Newparams.append('company', params.company)
        Newparams.append('page', params.page)
        Newparams.append('size', params.size)
        if (params.sort) {
          Newparams.append('sort', params.sort)
        }
      }
      params = Newparams
      var config = { params }
      const headers = await this.getRequestHeader()
      console.log(headers)
      config.headers = headers
      this.resultSearchType = resultSearchType
      const url = configs.ServerAddress + requests.getResults.url
      axios.get(`${url}${resultSearchType}`, config)
      // this.$utils
      //   .callAxios(
      //     requests.getResults.method,
      //     `${url}${resultSearchType}`,
      //     config
      //   )
        .then(res => {
          if (commons.isNull(res.data) || res.data === '') {
            this.handleGetResultsError('Could not found any anchors.')
          } else if (!commons.isValidStr(res.data.resultResponse)) {
            // labelId 검색인 경우, 한 개의 result만 응답으로 받는다.
            this.handleGetResultsResponse(res)
          } else {
            this.handleGetResultsResponse(res)
          }
        })
        .catch(error => this.handleGetResultsError(error))
    },
    async getRequestHeader () {
      const vuex = await JSON.parse(localStorage.getItem('vuex'))
      // const user = vuex.auth.user
      // for cookies
      const session = Cookies.get('sessionId')

      const loginState = vuex.auth.loginState
      const hearder = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8;'
      }
      const url = configs.ApiUrl
      // https 요청인 경우 API key 추가.
      if (url && url.toLowerCase().includes('https://')) {
        hearder['api-key'] = configs.ApiKey
      }
      // login 상태라면 sessionId 추가.
      if (loginState === true) hearder.sessionId = session
      // Cloud 버전이라면 Token추가.
      if (process.env.VUE_APP_PRODUCTION_TYPE === 'cloud') {
        hearder.Authorization = `Bearer ${vuex.auth.tokenAPI.accessToken}`
      }
      if (configs.keycloakLogin === true) {
        hearder.Authorization = `Bearer ${vuex.auth.tokenAPI}`
      }
      return hearder
    }
  }
}
</script>

<style></style>
