import { render, staticRenderFns } from "./AddAnchor.vue?vue&type=template&id=e2d004cc&scoped=true&"
import script from "./AddAnchor.vue?vue&type=script&lang=js&"
export * from "./AddAnchor.vue?vue&type=script&lang=js&"
import style0 from "./AddAnchor.vue?vue&type=style&index=0&id=e2d004cc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2d004cc",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VCol,VDialog,VRow,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/-36BVGaF/0/applicationg/aims-dashboard/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e2d004cc')) {
      api.createRecord('e2d004cc', component.options)
    } else {
      api.reload('e2d004cc', component.options)
    }
    module.hot.accept("./AddAnchor.vue?vue&type=template&id=e2d004cc&scoped=true&", function () {
      api.rerender('e2d004cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Multifunction/lbs/modal/AddAnchor.vue"
export default component.exports