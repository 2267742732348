<template>
  <v-dialog v-model="dialog" persistent scrollable width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="addBtnDisabled"
        text
        v-bind="attrs"
        v-on="on"
        class="btn"
        >{{ $t("New") }}</v-btn
      >
    </template>
    <v-card class="popup add_store_popup">
      <v-card-title>
        <h3 class="page-title-bar">
          <i class="ico ico-multi"></i>{{ $t("New Anchor") }}
        </h3>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="labelId"
              :label="Label_LabelId"
              :placeholder="$t('Input the label ID')"
              class="form-input"
              outlined
              dense
              hide-details
              clearable
              @keydown="clearTabindex"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="positionId"
              :label="Label_PositionId"
              :placeholder="$t('Input the Shelf Name')"
              :maxlength="50"
              class="form-input"
              outlined
              dense
              hide-details
              clearable
              @keydown="clearTabindex"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn @click="addAnchor" text icon class="btn">{{ $t("Save") }}</v-btn>
        <v-btn @click="cancelAddAnchor" text icon class="btn">{{
          $t("Cancel")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from '@/plugins/eventBus.js'
import commons from '@/plugins/commons'
import configs from '@/plugins/configs'

const requests = {
  addAnchor: {
    method: 'post',
    url: '/lbs/common/lbs/anchor/config'
  }
}

export default {
  props: ['user', 'addBtnDisabled'],
  data () {
    return {
      dialog: false,
      positionId: null,
      labelId: null
    }
  },
  computed: {
    Label_LabelId () {
      return this.$t('Label ID')
    },
    Label_PositionId () {
      return this.$t('Shelf Name')
    }
  },
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    cancelAddAnchor () {
      this.positionId = null
      this.labelId = null
      this.dialog = false
    },
    addAnchor () {
      const anchor = { positionId: this.positionId, labelId: this.labelId }
      const isValidData = commons.isValidData({
        LocationId: this.positionId,
        labelId: this.labelId
      })
      if (!isValidData.result) {
        const msg = commons.converMsg(
          isValidData.msg,
          commons.msgType.InvalidAnchorData
        )
        EventBus.$emit('messageAlert', this.$t(msg))
        return
      }
      const params = { company: this.user.company }
      const config = { params: params }
      const url = configs.ServerAddress + requests.addAnchor.url

      return this.$utils
        .callAxiosWithBody(
          requests.addAnchor.method,
          url,
          anchor,
          config
        )
        .then(res => {
          const result = res.data
          EventBus.$emit('messageAlert', this.$t(result.responseMessage))
          this.$emit('fireResetAnchors')
          this.cancelAddAnchor()
        })
        .catch(error => {
          console.log(error.response.status)
          if (error.response.status === 500) {
            EventBus.$emit(
              'messageAlert', this.$t('Something went wrong')
            )
          } else {
            EventBus.$emit(
              'messageAlert',
              this.$t(error.response.data.responseMessage)
            )
          }
          console.log(error)
          this.cancelAddAnchor()
        })
    }
  }
}
</script>

<style scoped>
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }

</style>
