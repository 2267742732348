<template>
  <div>
    <!-- Page title bar -->
    <h2 class="page-title-bar">
      <i class="ico ico-multi"></i>{{ $t('LBS : Anchor System') }}
    </h2>
    <!-- Anchors component -->
    <anchors />
    <!-- Results component -->
    <results />
  </div>
</template>

<script>
import EventBus from '@/plugins/eventBus.js'

import Anchors from './lbs/Anchors'
import Results from './lbs/Results'

export default {
  name: 'LBS',
  components: {
    Anchors,
    Results
  },
  data () {
    return {}
  },
  mounted () {
    this.clearTabindex()
    // 상단 공통 팝업 & 자동완성기능 사용유무
    EventBus.$emit('enableSelectedStores', true)
  },
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    }
  }
}
</script>
