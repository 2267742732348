var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", scrollable: "", width: "900" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "btn ml-2",
                      attrs: { disabled: _vm.settingBtnDisabled, text: "" },
                      on: { click: _vm.getLbsConfiguration }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(_vm._s(_vm.$t("Setting")))]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "page-title-bar" }, [
              _c("i", { staticClass: "ico ico-multi" }),
              _vm._v(_vm._s(_vm.$t("Anchor Setting")) + " ")
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        staticClass: "form-select",
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: _vm.Label_Hours,
                          placeholder: _vm.$t("Select the hour"),
                          items: _vm.settingOptions.startTime.hours
                        },
                        model: {
                          value: _vm.setting.startTime.hours,
                          callback: function($$v) {
                            _vm.$set(_vm.setting.startTime, "hours", $$v)
                          },
                          expression: "setting.startTime.hours"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        staticClass: "form-select",
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          placeholder: _vm.$t("Select the minute"),
                          items: _vm.settingOptions.startTime.minutes
                        },
                        model: {
                          value: _vm.setting.startTime.minutes,
                          callback: function($$v) {
                            _vm.$set(_vm.setting.startTime, "minutes", $$v)
                          },
                          expression: "setting.startTime.minutes"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panels",
                {
                  staticClass: "advancedSetting",
                  attrs: { flat: "", accordion: "" },
                  model: {
                    value: _vm.advancedSettingVisible,
                    callback: function($$v) {
                      _vm.advancedSettingVisible = $$v
                    },
                    expression: "advancedSettingVisible"
                  }
                },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { staticClass: "pl-0 pr-0" },
                        [
                          _c("i", { staticClass: "throwline" }),
                          _vm._v(_vm._s(_vm.$t("Show Advanced Setting")))
                        ]
                      ),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    staticClass: "form-input",
                                    attrs: {
                                      type: "number",
                                      min: _vm.anchorSettingRange.endTime.min,
                                      max: _vm.anchorSettingRange.endTime.max,
                                      label: _vm.Label_EndTime,
                                      placeholder: _vm.placeholders.endTime,
                                      clearable: "",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": ""
                                    },
                                    on: { keydown: _vm.clearTabindex },
                                    model: {
                                      value: _vm.setting.endTime,
                                      callback: function($$v) {
                                        _vm.$set(_vm.setting, "endTime", $$v)
                                      },
                                      expression: "setting.endTime"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    staticClass: "form-input",
                                    attrs: {
                                      type: "number",
                                      min:
                                        _vm.anchorSettingRange.gatheringEndTime
                                          .min,
                                      max:
                                        _vm.anchorSettingRange.gatheringEndTime
                                          .max,
                                      label: _vm.Label_GatheringEndTime,
                                      placeholder:
                                        _vm.placeholders.gatheringEndTime,
                                      clearable: "",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": ""
                                    },
                                    on: { keydown: _vm.clearTabindex },
                                    model: {
                                      value: _vm.setting.gatheringEndTime,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.setting,
                                          "gatheringEndTime",
                                          $$v
                                        )
                                      },
                                      expression: "setting.gatheringEndTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    staticClass: "form-input",
                                    attrs: {
                                      type: "number",
                                      min: _vm.anchorSettingRange.txPower.min,
                                      max: _vm.anchorSettingRange.txPower.max,
                                      label: _vm.Label_TxPower,
                                      placeholder: _vm.placeholders.txPower,
                                      clearable: "",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": ""
                                    },
                                    on: { keydown: _vm.clearTabindex },
                                    model: {
                                      value: _vm.setting.txPower,
                                      callback: function($$v) {
                                        _vm.$set(_vm.setting, "txPower", $$v)
                                      },
                                      expression: "setting.txPower"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    staticClass: "form-input",
                                    attrs: {
                                      type: "number",
                                      min:
                                        _vm.anchorSettingRange.txInterval.min,
                                      max:
                                        _vm.anchorSettingRange.txInterval.max,
                                      label: _vm.Label_TxInterval,
                                      placeholder: _vm.placeholders.txInterval,
                                      clearable: "",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": ""
                                    },
                                    on: { keydown: _vm.clearTabindex },
                                    model: {
                                      value: _vm.setting.txInterval,
                                      callback: function($$v) {
                                        _vm.$set(_vm.setting, "txInterval", $$v)
                                      },
                                      expression: "setting.txInterval"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "form-input",
                                    attrs: {
                                      type: "number",
                                      min: _vm.anchorSettingRange.threshold.min,
                                      max: _vm.anchorSettingRange.threshold.max,
                                      label: _vm.Label_Threshold,
                                      placeholder: _vm.placeholders.threshold,
                                      clearable: "",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": ""
                                    },
                                    on: { keydown: _vm.clearTabindex },
                                    model: {
                                      value: _vm.setting.threshold,
                                      callback: function($$v) {
                                        _vm.$set(_vm.setting, "threshold", $$v)
                                      },
                                      expression: "setting.threshold"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.handleClickSave }
                },
                [_vm._v(_vm._s(_vm.$t("Save")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.cancelAnchorSetting }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }